import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import { Socket } from "socket.io-client";
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { Tooltip, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { SelectChangeEvent } from '@mui/material/Select';
import { Table, TableBody, TableCell, TableRow, TableContainer, Paper, TableHead } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import '../../App.css';
import Doi from '../../components/Doi';
import { Item, Item2 } from '../../styles';
import AppSettings from '../../API/appSettings';
import { getPreview } from '../../services/Spectrum';
import { molToSvg } from '../../services/NameToMolecule';
import Header from '../../components/HeaderComponent';
import ErrorDialog from '../../components/ErrorDialog';
import MoleculeView from '../../components/MoleculeView';
import ProgressControl from '../../components/ProgressControl';
import { SolventControl } from '../../components/SolventControl';
import TimeoutError from '../../schemas/Exception/TimeoutError';
import HelpIconWithHint from '../../components/HelpIconWithHint';
import Config from '../../config.json';

import InternalError from '../../schemas/Exception/InternalError';
import { fetchGet, reportError } from '../../services/GettingData';
import NotAuthorizedError from "../../schemas/Exception/NotAuthorizedError";
import ISpectrumPreviewModel from '../../schemas/Spectrum/ISpectrumPreviewModel';
import IReportSpectraErrorModel from '../../schemas/IReportSpectraErrorModel';
import ReportConfirmationDialog from '../../components/Dialog/ReportConfirmationDialog';
import IGetResponse from '../../schemas/Spectrum/IGetResponse';
import ExpiredAccountError from '../../schemas/Exception/ExpiredAccountError';
import IMolWeightFilter from '../../schemas/IMolWeightFilter';
import { SpectrumSearchMode } from '../../schemas/Spectrum/SpectrumSearchMode';

import WebSocketConnection from '../Sockets/WebSocketConnection';
import Accept from '../../schemas/Compound/Accept';
import { SearchStatus } from '../../schemas/Compound/SearchStatus';
import IProgressInfo from '../../schemas/Compound/IProgressInfo';
import IFinishInfo from '../../schemas/Compound/IFinishInfo';
import MoleculeEditor from '../Compound/MoleculeEditor';


type ISpectrumPL = {
  location: SpectrumSearchPS
};

type SpectrumSearchPS = {
  state: {
    spectrumPreview: ISpectrumPreviewModel
  }
}

export const SpectrumSearchResultPage: React.FC<ISpectrumPL> = (props) => {

  const history = useHistory();

  const [currentPage, setCurrentPage] = React.useState<number>(Number(1));
  const [gotten, setGotten] = React.useState<number | undefined>(undefined);
  const gottenRef = React.useRef<number | undefined>(undefined);
  const [spectrumPreview, setSpectrumPreview] = React.useState<ISpectrumPreviewModel>(props.location.state?.spectrumPreview);
  const [spectrumRows, setSpectrumRows] = React.useState<any[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [isApplyEnabled, setApplyEnabled] = React.useState<boolean>(false);
  const [isConfirmationDialogOpened, setConfirmationDialogOpened] = React.useState<boolean>(false);
  const [spectrumParams, setSpectrumParams] = React.useState<{}>({});
  const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [moleculeWeightFilters, setMoleculeWeightFilters] = React.useState<IMolWeightFilter[]>((props.location.state?.spectrumPreview?.moleculeWeightFilters) ?? []);
  const [isMassFilterDialogOpen, setMassFilterDialogOpen] = React.useState(false);
  const [isRegionDialogOpen, setRegionDialogOpen] = React.useState(false);
  const [minMass, setMinMass] = React.useState("");
  const [maxMass, setMaxMass] = React.useState("");
  const [minRegion, setMinRegion] = React.useState("");
  const [maxRegion, setMaxRegion] = React.useState("");
  const [isApplyClicked, setApplyClicked] = React.useState(0);
  const [mandatoryPeaks, setMandatoryPeaks] = React.useState<number[]>((props.location.state && props.location.state.spectrumPreview?.mandatoryPeaks) ?? []);
  const [excludedPeaks, setExcludedPeaks] = React.useState<number[]>([]);
  const [solvent, setSolvent] = React.useState<string | null>(props.location.state?.spectrumPreview?.solvent ?? null);
  const [noShiftRegions, setNoShiftRegions] = React.useState<IMolWeightFilter[]>(props.location.state?.spectrumPreview?.noShiftRegions ?? []);

  const [searchStatus, setSearchStatus] = React.useState<SearchStatus>();
  const searchStatusRef = React.useRef<string | undefined>(searchStatus);

  const [connection, setConnection] = React.useState<Socket | undefined>(undefined);
  const [searchId, setSearchId] = React.useState<string>((props as any).match.params['searchid']);
  const [isLoadingSearch, setLoadingSearch] = React.useState<boolean>((props as any).match.params['searchid']);
  const searchRef = React.useRef<string | undefined>(searchId);

  const [moleculeSVG, setMoleculeSVG] = React.useState<string | undefined>(undefined);
  const [molecule, setMolecule] = React.useState<string>('');
  
  const [spectrumSearchResult, setSpectrumSearchResult] = React.useState<IGetResponse | undefined>(undefined);
  const spectrumSearchResultRef = React.useRef<IGetResponse | undefined>(spectrumSearchResult);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const pageChangeHandle = (event: React.ChangeEvent<unknown> | undefined, value: number) => {
    if (value == currentPage) return;
    setCurrentPage(value);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 0,
      hide: true
    },
    {
      field: 'svgContent',
      headerName: 'Compound',
      width: 170,
      sortable: false,
      editable: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.value !== 'data:image/svg+xml,null')
          return <div><a href={'/exact-search/' + params.row.compound_id}><img alt='' style={{ margin: '10px' }} width="150px" src={params.value} /></a></div>
        else return <div style={{ minHeight: '52px' }}></div>
      },
    },
    {
      field: 'spectrum_string',
      headerName: 'Spectrum string',
      flex: 0.4,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      cellClassName: 'spectrum_string',
    },
    {
      field: 'quality',
      headerName: 'Quality %',
      description: 'How adequately does this particlular spectrum correspond to the molecule',
      width: 150,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'overall_quality',
      description: 'Completeness of description of a particluar molecule in a particluar paper',
      headerName: 'Overall quality %',
      width: 150,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'urn',
      headerName: 'Reference',
      width: 250,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return Doi.DoiShort(params.value)
      },
    },
    {
      field: 'report',
      headerName: '',
      width: 150,
      editable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            className='report-error-spectrum'
            onClick={(e) => onReportClick(params)}
            variant='outlined'
            sx={{ color: '#505050', borderColor: '#505050' }}
          >Report Error</Button>
        );
      },
    },
  ];


  const onProgress = (progress: IProgressInfo) => {
    if (progress.search_id === searchRef.current) {
      if (!spectrumSearchResultRef.current) {
        setSpectrumSearchResult({
          total: progress.progress.n_items,
          items: [],
          id: progress.search_id,
          type: 'spectrum',
          pagination: { page: 1, per_page: Config.itemsPerPage }
        });
      } else {
        setSpectrumSearchResult({
          ...spectrumSearchResultRef.current,
          total: progress.progress.n_items
        });
      }

      if (searchStatusRef.current !== SearchStatus.InProgress) {
        setSearchStatus(SearchStatus.InProgress);
      }
    }
  };



  const getSearchParameters = async (searchId: string) => {
    const response = await fetchGet('/history/' + searchId, true, true, 3);
    return await response.json();
  }


  React.useEffect(() => {
    const fetchData = async () => {
      if ( isLoadingSearch) {
        setLoading(true);
        const query = await getSearchParameters(searchId);
        setSpectrumPreview({
          ...query.query,
          spectrum_type: query.query.type,
          spectrumSearchMode: query.query.mode
        });
        setMandatoryPeaks(query.query.mandatory_peaks);
        setMoleculeWeightFilters(query.query.weights);
        setSolvent(query.query.solvent);
        setLoading(false);
        setLoadingSearch(false);
        if (query.query.structure) {
          setMolecule(query.query.structure); 
          setMoleculeSVG(await molToSvg((query.query.structure)));
        }

        //const connection = socketConnection.getSocket();
        //connection.on('/error', (data) => onError(data));
        //connection.on('/search/accepted', (packet) => onAccept(packet, connection));

        //return () => {
        //  connection.off('/search/accepted', onAccept);
        //};
      }
    };
    fetchData();
  }, [isLoadingSearch]);


  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        history.push('/spectrum-search');
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);


  const onError = (packet: any) => {
    console.error('on error', packet);
    showError(packet.error);
    setLoading(false);
  };


  const onGet = (data: IGetResponse) => {
    if (searchStatusRef.current === SearchStatus.InProgress && data.total < 1) {
      if (spectrumSearchResultRef.current)
        data.total = spectrumSearchResult?.total ?? 0;
    }
    if (spectrumSearchResultRef.current &&
      data.total < spectrumSearchResultRef.current?.total &&
      spectrumSearchResultRef.current?.total > 0) {
      data.total = spectrumSearchResultRef.current?.total;
    }
    setSpectrumSearchResult(data);
    let localSpectrumRows = [];
    if (data?.items)
      for (let i = 0; i < data.items.length; i++) {
        let some = {
          ...data.items[i],
          svgContent: 'data:image/svg+xml,' + encodeURIComponent(data?.items[i].molecule_svg)
        };
        localSpectrumRows.push(some);
      }

    setSpectrumRows(localSpectrumRows);
    setLoading(false);
  };


  const get = (page: number) => {
    if (searchId && connection) {
      connection.on('/search/get', (data) => onGet(data));
      setLoading(true);
      connection.emit("/search/get", {
        "id": searchId,
        "page": page,
        'per_page': Config.itemsPerPage
      });
      setGotten(page);
    }
  };


  const onFinish = (packet: IFinishInfo, searchId: string) => {
    if (packet.id === searchId) {
      setSearchStatus(SearchStatus.Finished);
    }
  };


 const startStructSearch = async (_molecule: string): Promise<boolean> => {

      setGotten(undefined);
      setSpectrumSearchResult(undefined);

      WebSocketConnection.start({
        "type": "spectrum",
        "params":  {
          'type': spectrumPreview.spectrum_type,
          'peaks': spectrumPreview.peaks,
          'include_null_quality': false,
          'solvent': solvent === '' ? null : solvent,
          'structure': _molecule,
          'weights': moleculeWeightFilters,
          'mode': spectrumPreview.spectrumSearchMode,
          'mandatory_peaks': mandatoryPeaks,
          'no_shift_regions': noShiftRegions
        }
      }, onAccept, onError, 'publication-search' );
      return true;
  };


  const onAccept = (packet: Accept, connection: Socket) => {
    setConnection(connection);
    setLoading(true);
    setSearchStatus(SearchStatus.Started);
    setSearchId(packet.id);
    if (!isLoadingSearch)
      window.history.pushState(null, '', `/spectrum-search-result/${packet.id}`);
    console.log('/search/accepted compound', packet);
    connection.off('/search/accepted');
    connection.on('/search/progress', (data) => onProgress(data));
    connection.on('/search/finish', (data) => onFinish(data, packet.id));
    connection.on('/error', (data) => onError(data));
  }


  React.useEffect(() => {
    searchRef.current = searchId;
  }, [searchId]);


  React.useEffect(() => {
    searchStatusRef.current = searchStatus;
  }, [searchStatus]);


  React.useEffect(() => {
    gottenRef.current = gotten;
  }, [gotten]);


  React.useEffect(() => {
    if (spectrumPreview === undefined) {
      setLoading(true);
    }
  }, [spectrumPreview]);


  React.useEffect(() => {
    spectrumSearchResultRef.current = spectrumSearchResult;
  }, [spectrumSearchResult]);


  const openAddWeightDialog = () => {
    setMinMass('');
    setMaxMass('');
    setMassFilterDialogOpen(true);
  };


  const openAddRegionDialog = () => {
    setMinRegion('');
    setMaxRegion('');
    setRegionDialogOpen(true);
  };


  React.useEffect(() => {
    if (!isLoadingSearch)
      startStructSearch(molecule);
  }, [isApplyClicked, isLoadingSearch]);


  React.useEffect(() => {
    if (isLoadingSearch) return
    const fetchData = async () => {
      try {
        const spectrumPicture = await getPreview(spectrumPreview);
        setSpectrumPreview(spectrumPicture);
      } catch (e: any) {
        if (e instanceof NotAuthorizedError)
          history.push({ pathname: '/login', state: { backTo: '/spectrum-search', welcomeMessage: true } });
        if (e instanceof ExpiredAccountError)
          history.push({ pathname: '/personal', state: { welcomeMessage: true, expired: true } });

        if (e instanceof TimeoutError || e instanceof ReferenceError || e instanceof InternalError)
          showError(e.toString());

        console.error(e);
        showError(e.toString());
      }
    };
    fetchData().catch(console.error);
  }, [isLoadingSearch]);


  const onReportClick = async (params: any) => {
    setSpectrumParams(params);
    setConfirmationDialogOpened(true);
  };


  React.useEffect(() => {
    if ((searchStatus === SearchStatus.InProgress ||searchStatus === SearchStatus.Finished) 
        && currentPage != gottenRef.current)
      { get(currentPage);
      }
     
  }, [searchStatus]);


  React.useEffect(() => {
    if (searchStatus === SearchStatus.InProgress || searchStatus === SearchStatus.Finished)
      get(currentPage);
  }, [currentPage]);


  const onCloseErrorDialog = () => {
    setErrorDialogOpened(false);
  };


  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setErrorDialogOpened(true);
  };


  const onCloseDialog = async (confirmed: boolean, mistakeComment: string) => {
    setConfirmationDialogOpened(false);
    let params = spectrumParams as any;
    if (confirmed && params.row) {
      const spectrError: IReportSpectraErrorModel = {
        spectrum_id: params.row.id,
        spectrum_string: params.row.spectrum_string,
        comment: mistakeComment
      };
      const errors = [spectrError];
      await reportError(JSON.stringify(errors));
    }
    setSpectrumParams({});
  };


  const applyFilters = () => {
    setGotten(undefined);
    pageChangeHandle(undefined, 1);
    setApplyClicked(isApplyClicked + 1);
    setApplyEnabled(false);
  };

  const addMassEntity = () => {
    setApplyEnabled(true);
    setMoleculeWeightFilters(prevFilters => [
      ...prevFilters,
      {
        start: parseInt(minMass, 10),
        end: parseInt(maxMass, 10),
      },
    ]);
    setMassFilterDialogOpen(false);
  };


  const addRegionEntity = () => {
    setNoShiftRegions(prevFilters => [
      ...prevFilters,
      {
        start: parseInt(minRegion, 10),
        end: parseInt(maxRegion, 10),
      },
    ]);
    setRegionDialogOpen(false);
    setApplyEnabled(true);
  };


  const onDelPeak = (peak: number) => {
    setExcludedPeaks([...excludedPeaks, peak]);
    setApplyEnabled(true);
  };


  const handleMandatoryPeaksChange = (peak: number) => {
    setApplyEnabled(true);
    setMandatoryPeaks(prev => {
      const isPeakPresent = prev.includes(peak);
      if (isPeakPresent) {

        return prev.filter(p => p !== peak);
      } else
        return [...prev, peak];
    });
  };

  const onDelMWeight = (toDelete: any) => {
    setMoleculeWeightFilters((prev) => prev.filter((el) => el.start + '-' + el.end !== toDelete));
    setApplyEnabled(true);
  }

  const onDelRegion = (toDelete: any) =>
    setNoShiftRegions((prev) => prev.filter((el) => el.start + '-' + el.end !== toDelete));


  const changeSolventHandler = (e: SelectChangeEvent) => {
    setSolvent(e.target.value === 'any' ? '' : e.target.value);
    setApplyEnabled(true);
  };

  const formatSpectrumSearchMode = (spectrumSearchMode: string | undefined) => {
    return (spectrumSearchMode ?? '')
      .replace(/_/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };


  const onUpdateMolecule = (molecule: string, moleculeSVG) => {
    setMolecule(molecule);
    setMoleculeSVG(moleculeSVG);
    setApplyEnabled(true);
  }
  //setMoleculeWeightFilters((prev) => prev.filter((el) => el.start + '-' + el.end !== toDelete));


  const pngString = encodeURIComponent(spectrumPreview?.graph ? spectrumPreview.graph : '');
  const dataUri = `data:image/png;base64,${pngString}`;

  const gotoSpectr = (spectrumId: string, spectrumPreview?: ISpectrumPreviewModel) => {
    localStorage.setItem(spectrumId, JSON.stringify(spectrumPreview));
    window.open('/spectrum-compare/' + spectrumId, '_blank');
  };

  if (isLoadingSearch) {
    return (
      <>
        <ProgressControl isLoading={isLoading} showTime={true} /> :
      </>
    );
  }
  return (
    <>
      <Grid container spacing={0} className='main-frame'>
        <Grid md={12}>
          <Item2><div style={{ height: '2em' }}></div></Item2>
        </Grid>
        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="lg" fullWidth>
          <MoleculeEditor 
            updateMolecule={onUpdateMolecule}
            editorLoaded={() => { setLoading(false); }}
            closeWindow={() => setIsModalOpen(false)}
            previousMolecule={molecule} />
        </Dialog>

        <Dialog open={isMassFilterDialogOpen} onClose={() => setMassFilterDialogOpen(false)}>
          <DialogTitle>Mass Region</DialogTitle>
          <DialogContent>
            <TextField name="minMass" label="Min Mass" required style={{ margin: '.5em' }} type="number"
              onChange={e => setMinMass(e.target.value)}
              inputProps={{
                step: "1",
                pattern: "\\d*"
              }} />
            <TextField name="maxMass" label="Max Mass" required style={{ margin: '.5em' }} type="number"
              onChange={e => setMaxMass(e.target.value)}
              inputProps={{
                step: "1",
                pattern: "\\d*"
              }} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" disabled={!(maxMass && minMass) || (Number(minMass) >= Number(maxMass))} type="submit" onClick={addMassEntity}>Ok</Button>
            <Button variant='outlined' onClick={() => setMassFilterDialogOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isRegionDialogOpen} onClose={() => setRegionDialogOpen(false)}>
          <DialogTitle>No Shift Region</DialogTitle>
          <DialogContent>
            <TextField name="minMass" label="Min no region" required style={{ margin: '.5em' }} type="number"
              onChange={e => setMinRegion(e.target.value)}
              inputProps={{
                step: "1",
                pattern: "\\d*"
              }} />
            <TextField name="maxMass" label="Max no region" required style={{ margin: '.5em' }} type="number"
              onChange={e => setMaxRegion(e.target.value)}
              inputProps={{
                step: "1",
                pattern: "\\d*"
              }} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" disabled={!(maxRegion && minRegion) || (Number(minRegion) >= Number(maxRegion))} type="submit" onClick={addRegionEntity}>Ok</Button>
            <Button variant='outlined' onClick={() => setRegionDialogOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <ReportConfirmationDialog isDialogOpened={isConfirmationDialogOpened} onClose={onCloseDialog} />
        <ErrorDialog isDialogOpened={isErrorDialogOpened}
          errorMessage={errorMessage}
          warningMessage={''}
          onClose={onCloseErrorDialog} />
        <Grid xs={12}>
          <Header title={formatSpectrumSearchMode(spectrumPreview?.spectrumSearchMode) + ' Spectrum Search'} helpAddress='help#htuss' showLogin={true} />
        </Grid>

        <Grid md={12} container spacing={0} style={{ marginTop: '4em' }}>
          <Grid
            spacing={0}
            md={3}
            style={{ minWidth: "270px" }}
            direction="column"
            alignItems="center"
            justifyContent="center">
            <div style={{ paddingBottom: '0em' }}><span className='box-title'>Substructure filter</span></div>
            <Item style={{ width: "250px", marginTop: '10px' }}>
              <div onClick={() => {
                setLoading(true);
                setIsModalOpen(true)
              }}>
                <MoleculeView svgContent={moleculeSVG} 
                  isMoleculeInContainer={molecule!==''} 
                  spectrumPreview={{
                  ...spectrumPreview!,
                  solvent: solvent,
                  moleculeWeightFilters: moleculeWeightFilters,
                  mandatoryPeaks: mandatoryPeaks
                }
                } /></div>
            </Item>
          </Grid>

          {spectrumPreview.spectrumSearchMode === SpectrumSearchMode.REACTION_MIXTURE_MODE &&
            <Grid
              spacing={0}
              md={3}
              direction="column"
              alignItems="center"
              justifyContent="center">
              {spectrumPreview?.graph ?
                <>
                  <div>Requested Spectrum</div>
                  <Grid md={10}
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                    <Item style={{ width: '100%' }}>
                      <div className='graph-container' style={{ paddingBottom: '2px' }}>
                        <img alt=''
                          className='graph-qc'
                          src={dataUri}
                          style={{ maxWidth: '100%' }} />
                      </div>
                    </Item>
                  </Grid></> : ''}

              <div style={{ marginTop: '2em', paddingRight: '5em' }}>
                <SolventControl anyPossible={true}
                  onChange={changeSolventHandler}
                  selected={solvent} />
              </div>
            </Grid>}


          {spectrumPreview.spectrumSearchMode === SpectrumSearchMode.EXACT_MODE &&
            <Grid
              spacing={0}
              md={3}
              direction="column"
              alignItems="center"
              justifyContent="center">
              {spectrumPreview?.graph ?
                <>
                  <div>Requested Spectrum</div>
                  <Grid md={10}
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                    <Item style={{ width: '100%' }}>
                      <div className='graph-container' style={{ paddingBottom: '2px' }}>
                        <img alt=''
                          className='graph-qc'
                          src={dataUri}
                          style={{ maxWidth: '100%' }} />
                      </div>
                    </Item>
                  </Grid></> : ''}

              <div style={{ marginTop: '2em', paddingRight: '5em' }}>
                <SolventControl anyPossible={true}
                  onChange={changeSolventHandler}
                  selected={solvent} />
              </div>

            </Grid>}


          {spectrumPreview.spectrumSearchMode === SpectrumSearchMode.UNKNOWN_COMPOUND_MODE &&
            <Grid
              spacing={0}
              md={2}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ marginRight: '1em' }}>
              Query Peaks
              <Grid md={12} style={{ minHeight: '20px' }}>
                <TableContainer component={Paper} style={{ maxHeight: '280px', overflowY: 'auto' }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Peak ppm</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {spectrumPreview.peaks.filter(peak => !excludedPeaks.includes(peak)).sort((a, b) => a - b).map((peak, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ width: '50px', padding: '.5em' }}>{peak}</TableCell>
                          <TableCell style={{ width: '50px', padding: '.5em' }}>
                            <Button variant="outlined"
                              title='Remove'
                              style={{ width: '50px', padding: '.2em' }}
                              size="small"
                              onClick={() => {
                                onDelPeak(peak)
                              }}>X</Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>}



          {spectrumPreview.spectrumSearchMode === SpectrumSearchMode.UNKNOWN_COMPOUND_MODE &&
            <Grid
              spacing={0}
              md={2}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ marginRight: '1em' }}>
              No Shift Regions
              <Grid md={12} style={{ minHeight: '20px' }}>

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {noShiftRegions.map((filter, index) => (
                        <TableRow key={index}>
                          <TableCell>{filter.start}</TableCell>
                          <TableCell>{filter.end}</TableCell>
                          <TableCell>
                            <Button variant="outlined"
                              title='Remove'
                              style={{ width: '50px' }}
                              size="small"
                              onClick={() => onDelRegion(filter.start + '-' + filter.end)}>X</Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid md={12}>
                <Box display="flex" justifyContent="center">
                  <Button style={{ marginTop: '1em', width: '180px' }} variant='outlined' onClick={openAddRegionDialog}>Add Region</Button>
                </Box>
              </Grid>
            </Grid>}


          <Grid container
            spacing={0}
            md={2} xs={12}
            direction="column">
            Molecular Weight Filter
            <Grid md={12} style={{ minHeight: '20px' }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Start</TableCell>
                      <TableCell>End</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {moleculeWeightFilters.map((filter, index) => (
                      <TableRow key={index}>
                        <TableCell>{filter.start}</TableCell>
                        <TableCell>{filter.end}</TableCell>
                        <TableCell>
                          <Button variant="outlined"
                            title='Remove'
                            style={{ width: '50px' }}
                            size="small"
                            onClick={() => onDelMWeight(filter.start + '-' + filter.end)}>X</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid md={12}>
              <Box display="flex" justifyContent="center">
                <Button style={{ marginTop: '1em', width: '180px' }} variant='outlined' onClick={openAddWeightDialog}>Add Mass Region</Button>
              </Box>
            </Grid>

            <span style={{ fontSize: 'small', margin: '1em' }}>
              Only molecules with molar mass in the prescribed range(s) will be shown
            </span>
          </Grid>


          {spectrumPreview.spectrumSearchMode === SpectrumSearchMode.REACTION_MIXTURE_MODE &&
            <Grid container
              spacing={0}
              md={2} xs={12}
              direction="column">
              Mandatory peak filter
              <Grid md={12} style={{ minHeight: '20px' }}>
                <TableContainer component={Paper} style={{ maxHeight: '280px', overflowY: 'auto' }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Peak position</TableCell>
                        <TableCell>Mark as mandatory</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {spectrumPreview?.peaks.sort((a, b) => a - b).map((peak, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ padding: '.2em', textAlign: 'center' }}>{peak}</TableCell>
                          <TableCell style={{ padding: '.2em', textAlign: 'center' }}>
                            <Checkbox checked={mandatoryPeaks.includes(peak)}
                              onChange={() => handleMandatoryPeaksChange(peak)}
                            /></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid md={12}>
                <Box display="flex" justifyContent="center"></Box>
              </Grid>
              <span style={{ fontSize: 'small', margin: '1em' }}>
                Only spectra including all these peaks will be shown
              </span>
            </Grid>}


          <Grid container
            spacing={0}
            md={2} xs={12}
            direction="column"
            alignItems="center"
            justifyContent="center">
            <Grid md={12} style={{ minWidth: '220px' }}>
              <Item2 style={{ fontSize: '2em', paddingTop: '0px', paddingBottom: '0px', marginTop: '1em' }}>
                <Link to={{ pathname: '/spectrum-search', state: {} }} className='MuiButton' >
                  <Tooltip arrow title='start new search'>
                    <Button variant="contained">New Search</Button>
                  </Tooltip>
                </Link>
                <HelpIconWithHint title={''}
                  text={'Drop results and submit new spectrum'}
                  handleOpen={() => { }} />
              </Item2>
            </Grid>
            <Grid md={12} style={{ minWidth: '220px' }}>
              <Item2 style={{ fontSize: '2em', paddingTop: '0px', paddingBottom: '0px' }}>
                <Link to={{
                  pathname: '/spectrum-search',
                  state: { 'spectrumPreview': spectrumPreview }
                }}
                  className='MuiButton' >
                  <Tooltip arrow title='modify query'>
                    <Button variant="contained">Edit Search</Button>
                  </Tooltip>
                </Link>
                <HelpIconWithHint title={''}
                  text={'Drop results and modify the query'}
                  handleOpen={() => { }} />
              </Item2>

              <Item2 style={{ fontSize: '2em', paddingTop: '0px', paddingBottom: '0px' }}>
                <Button color="success" disabled={!isApplyEnabled} variant="contained" onClick={applyFilters}>Apply</Button>
                <HelpIconWithHint title={''}
                  text={'Apply extra filter [mass, structure, solvent, mandatory peaks]'}
                  handleOpen={() => { }} />
              </Item2>
            </Grid>
          </Grid>
        </Grid>

        {spectrumPreview.spectrumSearchMode === SpectrumSearchMode.UNKNOWN_COMPOUND_MODE &&
          <Grid container
            spacing={0}
            md={12}
            direction="column"
            alignItems="center"
            justifyContent="center"
            marginTop={'2em'}>
            <Grid
              spacing={0}
              md={4}
              direction="column"
              alignItems="center"
              justifyContent="center">
              {spectrumPreview?.graph ?
                <>
                  <div>Requested Spectrum</div>
                  <Grid md={10}
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                    <Item style={{ width: '100%' }}>
                      <div className='graph-container' style={{ paddingBottom: '2px' }}>
                        <img alt=''
                          className='graph-qc'
                          src={dataUri}
                          style={{ maxWidth: '100%' }} />
                      </div>
                    </Item>
                  </Grid></> : ''}


              <Grid md={12} style={{ minHeight: '20px' }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Peak ppm</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

          </Grid>}

        <Grid xs={12} container style={{ marginTop: '2em' }}>
          <Grid xs={12} style={{ marginTop: '0em', padding: '0em' }} spacing={2}>
            <Item style={{ fontSize: '2em', paddingTop: '0px', paddingBottom: '0px' }}>
              <Box sx={{ width: '100%' }}>
                {isLoading ? <ProgressControl isLoading={isLoading} showTime={true} /> :
                  <DataGrid
                    autoHeight
                    getRowHeight={() => 'auto'}
                    rows={spectrumRows ? spectrumRows : []}
                    columns={columns}
                    onCellDoubleClick={(params, event) => {
                      if (!event.ctrlKey) {
                        event.defaultMuiPrevented = true;
                      }
                      if (params.field === 'urn') Doi.gotoDoi(params.row.urn);
                      if (params.field === 'spectrum_string') gotoSpectr(params.row.id, spectrumPreview);
                    }}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                  />}
              </Box>
            </Item>
          </Grid>

          <Grid xs={12} className='pagination-line' style={{ display: "inline" }} spacing={1}>
            {!isLoading && spectrumSearchResult?.items &&
              <Pagination style={{ marginTop: '1em' }}
                count={Math.ceil(spectrumSearchResult.total / spectrumSearchResult.pagination.per_page)}
                page={currentPage} onChange={pageChangeHandle} />
            }
            {!isLoading && searchStatus === SearchStatus.InProgress && (
              <span style={{ maxWidth: '10em' }}>
                <LinearProgress />
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );

}
export default SpectrumSearchResultPage;