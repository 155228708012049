import { io, Socket } from "socket.io-client";
import { useHistory } from "react-router-dom";
import CryptoJS from 'crypto-js';

import { getSession } from "../../services/Login";
import { refreshToken } from "../../services/Login";
import NotAuthorizedError from "../../schemas/Exception/NotAuthorizedError";
import Accept from '../../schemas/Compound/Accept';

interface SearchStackEntry {
  query: { type: string, params: object };
  hash: string;
  timestamp: number;
  onAccept: (packet: Accept, connection: Socket) => void;
  onError: (message: string) => void;
}

class WebSocketConnection {
  private static instance: WebSocketConnection;
  private static jwt: string;

  private socket: Socket;

  private constructor() {
    this.initializeSocket();
  }

  public static getInstance(forceNew: boolean = false): WebSocketConnection {
    if (!WebSocketConnection.instance || forceNew) {
      WebSocketConnection.instance = new WebSocketConnection();
    }
    return WebSocketConnection.instance;
  }

  public reconnectWithNewToken() {
    if (this.socket) {
      this.socket.disconnect();
    }
    this.initializeSocket();
    if (WebSocketConnection.searchStack.length > 0) {
      const stack = WebSocketConnection.searchStack.pop();
      WebSocketConnection.start(stack?.query, stack?.onAccept, stack?.onError);
    }
  }

  private static searchStack: SearchStackEntry[] = [];

  private static calculateParamsHash(query: { type: string, params: object }): string {
    const sortedParamsString = JSON.stringify(query.params, Object.keys(query.params).sort());
    const hash = CryptoJS.MD5(sortedParamsString).toString();
    return hash;
  }


  private static acceptListener(packet: Accept, onAccept, connection: Socket) {
    const hash = WebSocketConnection.calculateParamsHash({ type: packet.type, params: packet.params });
    const stack = this.searchStack.filter(entry => entry.hash == hash);
    this.searchStack = WebSocketConnection.searchStack.filter(entry => entry.hash !== hash);
    if (stack.length > 0) {
      connection.off('/error');
      connection.off('/search/accepted');
      onAccept(packet, connection);
    }
    else {
      console.error('no stack entry found for packet', packet);
    }

  }


  private static errorListener(message: string, onError, connection: Socket) {
    console.error('error listener in websocket, packet', message);
    this.searchStack = []
    connection.off('/error');
    connection.off('/search/accepted');
    onError(message);
  }



  public static start(query: { type: string, params: object },
    onAccept: (packet: Accept, connection: any) => void,
    onError: (message: string) => void,
    backTo: string | undefined = undefined,
  ): WebSocketConnection {
    const instance = WebSocketConnection.getInstance();
    const connection = instance.getSocket();
    connection.on('/error', (packet) => WebSocketConnection.errorListener(packet, onError, connection));
    connection.on('/search/accepted', (packet) => WebSocketConnection.acceptListener(packet, onAccept, connection));

    // Create stack entry
    const stackEntry: SearchStackEntry = {
      query: query,
      hash: this.calculateParamsHash(query),
      timestamp: Date.now(),
      onAccept: onAccept,
      onError: onError
    };
    this.searchStack.push(stackEntry);
    connection.emit("/search/start", query);
    return instance;
  }


  private initializeSocket() {
    const url = undefined;
    const path = "/api/v2/socket.io";
    const session = getSession();

    if (this.socket) {
      this.socket.disconnect();
    }

    this.socket = io(url, {
      extraHeaders: {
        "Access-Control-Allow-Origin": "*",
      },
      transports: ["websocket", "polling", "flashsocket"],
      auth: {
        'mode': session === undefined ? 'anonymous' : 'jwt',
        'jwt': session?.access_token
      },
      path,
    });

    this.socket.on("connect_error", async (err) => {
      console.log("connect error", err);
      if (err.message.includes("access_level")) {
        alert("You are not authorized to access this page.");
      }

      if (err.message === "Signature has expired") {
        try {
          const session = getSession();
          if (session?.refresh_token) {
            await refreshToken(session.refresh_token);
            this.reconnectWithNewToken();
          }
        } catch (e) {
          console.error("Failed to refresh token:", e);
          if (e instanceof NotAuthorizedError)
            window.open('/login', '_self');
        }
      }
    });

    this.socket.on("connect", () => {
      this.initializeListeners();
    });

    this.socket.on("disconnect", () => {
      console.error("socket disconnected");
    });

    this.socket.on("reconnect_attempt", () => {
    });

    this.socket.on('message', (data) => {
      console.error('Message ' + data);
    });

    this.socket.on("reconnect", () => {
      console.log("socket reconnected");
    });
  }


  public getSocket(): Socket {
    return this.socket;
  }

  private initializeListeners(): void {
    this.socket.on("connect", () => {
      console.log("connection established");
    });

    this.socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });
  }

  public clearSearches(): void {
    console.log(this.socket.emit("/debug/clear"));
  }
}

export default WebSocketConnection;